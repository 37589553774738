export const enum Updaters {
  Basket = 'BASKET',
  Checkout = 'CHECKOUT',
  Sync = 'SYNC',
  QuickOrder = 'QUICK_ORDER',
  SalesAgreement = 'SALES_AGREEMENT',
}

export const enum CreditLimitValidationResult {
  Valid = 'VALID',
  Error = 'ERROR',
  Warning = 'WARNING',
  ForceOnlinePayment = 'FORCE_ONLINE_PAYMENT',
}

export const enum NonOrderableReason {
  NotAvailable = 'NOT_AVAILABLE',
  NotInStock = 'NOT_IN_STOCK',
  QuantityNotInStock = 'QUANTITY_NOT_IN_STOCK',
  Custom = 'CUSTOM',
  CustomerSpecificAssortments = 'CUSTOMER_SPECIFIC_ASSORTMENTS',
}

export const enum BasketType {
  BASKET = 'BASKET',
  QUOTEBASKET = 'QUOTEBASKET',
};

//171082 - 3.5.Two shopping carts (orders and quotes)
export const BasketTypes = Object.freeze({
  [BasketType.BASKET]: BasketType.BASKET,
  [BasketType.QUOTEBASKET] : BasketType.QUOTEBASKET,
});

//171082 - 3.5.Two shopping carts(orders and quotes)
export const ActiveBasketType = Object.freeze({
    ORDER:  BasketType.BASKET,
    QUOTE: BasketType.QUOTEBASKET,
    ALL:'ALL',
});

/*203597 - [CIL] Cambridge Isotope: Phase 3 - Contacts Registration process update*/
export const ShopAccountType = Object.freeze({
    Customer : 'CUSTOMER',
    Contact : 'CONTACT',
    SalesAgent : 'SALES_AGENT',
    Prospect : 'PROSPECT',
});
