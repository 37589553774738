import type { ModifiedLine, SalesAgreementLine, ReceivedBasket, ReceivedSummary, SummaryModel, Model, SalesAgreementInfoData, SalesAgreementInfo, EndCustomerInput } from './types';
import { pageSize } from './queries';
import { filterExistingImages } from 'utils/helpers';
import { isReceivedBasket, isSummaryModel } from './helpers';
import { BasketType } from './constants';

export const BASKET_ADD_PRODUCTS = 'BASKET_ADD_PRODUCTS' as const;
export const addProducts = (
    lines: BasketLineInput[],
    updatedById: string | undefined,
    agreementId: string | null = null,
    basketType: BasketType | null = null,
) => ({
    type: BASKET_ADD_PRODUCTS,
    payload: { lines, updatedById, agreementId, basketType },
});

/*171082 - 3.5.Two shopping carts(orders and quotes)*/
export const BASKET_HOVER = 'BASKET_HOVER' as const;
export const basketHover = (basketButtonClicked: string | null) => ({
    type: BASKET_HOVER,
    payload: { basketButtonClicked },
});

/*183791 2.8.Packing requirements(change to requirement 3.5 from Phase 1)*/
type BasketLineInput = {
    productId: string;
    variantId?: string;
    quantity: number;
    uomId?: string;
    salesAgreementLineId?: string;
    orderLineComment?: string;  //2.10 order line comments - #183794
    packingRequirements?: string;
};

export const BASKET_UPDATED = 'BASKET_UPDATED' as const;
export const basketUpdated = (updaterId: string | undefined, modifiedDate: number) => ({
    type: BASKET_UPDATED,
    payload: {
        date: modifiedDate,
        updaterId,
    },
});

export const BASKET_RECEIVED = 'BASKET_RECEIVED' as const;
export const basketReceived = (
    basket: ReceivedBasket | Model,
    salesAgreementInfo: SalesAgreementInfoData | SalesAgreementInfo | null = null,
    pageIndex = 0,
) => ({
    type: BASKET_RECEIVED,
    payload: {
        basket: isReceivedBasket(basket) ? filterImages(basket) : basket,
        salesAgreementInfo,
        page: {
            index: pageIndex,
            size: pageSize,
        },
    },
});

export const BASKET_PAGE_REQUESTED = 'BASKET_PAGE_REQUESTED' as const;
export const reloadBasketPage = (shouldCountSubLines = false) => ({
    type: BASKET_PAGE_REQUESTED,
    payload: { countSubLines: shouldCountSubLines },
});
export const requestBasketPage = (pageIndex: number, shouldCountSubLines = false) => ({
    type: BASKET_PAGE_REQUESTED,
    payload: { index: pageIndex, countSubLines: shouldCountSubLines },
});

export const BASKET_SUMMARY_REQUESTED = 'BASKET_SUMMARY_REQUESTED' as const;
export const requestBasketSummary = (calculated: boolean) => ({
    type: BASKET_SUMMARY_REQUESTED,
    payload: { calculated },
});

export const BASKET_SUMMARY_RECEIVED = 'BASKET_SUMMARY_RECEIVED' as const;
export const basketSummaryReceived = (
    basket: ReceivedSummary | SummaryModel | null,
    salesAgreementInfo?: SalesAgreementInfoData | SalesAgreementInfo | null,
    modifiedDate?: string | number | null,
) => ({
    type: BASKET_SUMMARY_RECEIVED,
    payload: {
        basket: basket && isSummaryModel(basket) ? basket : filterImages(basket),
        salesAgreementInfo,
        modifiedDate,
    },
});

export const BASKET_UPDATE = 'BASKET_UPDATE' as const;
export const modifyBasket = (modified: ModifiedLine[] | null = null, code: string | null = null, countSubLines = false, index?: number) => ({
    type: BASKET_UPDATE,
    payload: { modified, code, countSubLines, index },
});
export const saveModifiedBasket = (modified: ModifiedLine[], writeOnly = true) => ({
    type: BASKET_UPDATE,
    payload: { modified, writeOnly },
});

export const BASKET_CLEAR = 'BASKET_CLEAR' as const;
export const clearBasket = (remove = false) => ({
    type: BASKET_CLEAR,
    payload: { remove },
});

/*171082 - 3.5.Two shopping carts(orders and quotes)*/
export const QUOTE_BASKET_CLEAR = 'QUOTE_BASKET_CLEAR' as const;
export const clearQuoteBasket = (remove = false) => ({
    type: QUOTE_BASKET_CLEAR,
    payload: { remove },
});

export const BASKET_REMOVE_AGREEMENT = 'BASKET_REMOVE_AGREEMENT' as const;
export const removeAgreement = () => ({
    type: BASKET_REMOVE_AGREEMENT,
});

export const BASKET_AGREEMENT_LINES_REQUESTED = 'BASKET_AGREEMENT_LINES_REQUESTED' as const;
export const requestAgreementLines = (agreementId: string, productId: string, basketLineId: string) => ({
    type: BASKET_AGREEMENT_LINES_REQUESTED,
    payload: { agreementId, productId, basketLineId },
});

export const BASKET_AGREEMENT_LINES_RECEIVED = 'BASKET_AGREEMENT_LINES_RECEIVED' as const;
export const receiveAgreementLines = (agreementLines: SalesAgreementLine[], basketLineId: string) => ({
    type: BASKET_AGREEMENT_LINES_RECEIVED,
    payload: { agreementLines, basketLineId },
});

/*171082 - 3.5.Two shopping carts(orders and quotes)*/
export const BASKET_CHANGETYPE = 'BASKET_CHANGETYPE' as const;
export const changeBasketType = (basketType: string) => ({ type: BASKET_CHANGETYPE, payload: { basketType } });

/*171082 - 3.5.Two shopping carts(orders and quotes)*/
export const BASKET_CHANGETYPE_REDIRECTTO_CHECKOUT = 'BASKET_CHANGETYPE_REDIRECTTO_CHECKOUT' as const;
export const changeBasketTypeRedirectToCheckOut = (basketType: string, isQuote: boolean) => ({ type: BASKET_CHANGETYPE_REDIRECTTO_CHECKOUT, payload: { basketType, isQuote } });

export const BASKET_BROADCAST = 'BASKET_BROADCAST' as const;

// Creates action to notify another tabs about new basket data.
export const broadcastBasket = (payload: BroadcastData) => ({ type: BASKET_BROADCAST, payload });
type BroadcastData = ({ basket: Model } | { summary: SummaryModel | null }) & {
    language: number | null;
    modifiedDate: number;
    salesAgreementInfo: SalesAgreementInfo;
};

export const BASKET_ARRIVED = 'BASKET_ARRIVED' as const;
export const basketArrived = (basket: Model, salesAgreementInfo: SalesAgreementInfo, modifiedDate: number) => ({
    type: BASKET_ARRIVED,
    payload: { basket, salesAgreementInfo, modifiedDate },
});

//Ticket 171387: Cambridge Isotope 3.9. End customer
export const END_CUSTOMER_FORM_SUBMIT = 'END_CUSTOMER_FORM_SUBMIT' as const;
export const sendEndCustomerForm = (basketLineId: string, fields: any) => ({
    type: END_CUSTOMER_FORM_SUBMIT,
    payload: { basketLineId, fields },
});
export const END_CUSTOMER_ADDRESS_SUBMITTED = 'END_CUSTOMER_ADDRESS_SUBMITTED' as const;
export const endCustomerAddressSubmitted = (addressInfo: any, info: any, basketLineId: any) => ({
    type: END_CUSTOMER_ADDRESS_SUBMITTED,
    payload: { addressInfo, info, basketLineId },
});
export const END_CUSTOMER = 'END_CUSTOMER' as const;
export const endCustomerSelectAddress = (id: string | undefined, basketLineId:string, formatted: any) => ({
    type: END_CUSTOMER,
    payload: { id, basketLineId, formatted },
});
export const END_CUSTOMER_ADDRESS_SELECTED = 'END_CUSTOMER_ADDRESS_SELECTED' as const;
export const endCustomerAddressSelected = (id:any, basketLineId:any, formatted:any) => ({
    type: END_CUSTOMER_ADDRESS_SELECTED,
    payload: { id, basketLineId, formatted },
});

function filterImages<T extends ReceivedBasket | ReceivedSummary | null>(basket: T): T {
    if (basket?.productLines.list)
        for (const line of basket.productLines.list)
            if ('product' in line && line.product?.images)
                line.product.images = filterExistingImages(line.product.images);

    return basket;
}

export type AddProductsAction = ReturnType<typeof addProducts>;
export type BasketHoverAction = ReturnType<typeof basketHover>;/*171082 - 3.5.Two shopping carts(orders and quotes)*/
export type BasketUpdatedAction = ReturnType<typeof basketUpdated>;
export type BasketReceivedAction = ReturnType<typeof basketReceived>;
export type BasketSummaryReceivedAction = ReturnType<typeof basketSummaryReceived>;
export type BasketArrivedAction = ReturnType<typeof basketArrived>;
export type ReceiveAgreementLinesAction = ReturnType<typeof receiveAgreementLines>;
export type BasketUpdateAction = ReturnType<typeof modifyBasket | typeof saveModifiedBasket>;
export type RequestBasketPageAction = ReturnType<typeof requestBasketPage>;
export type ReloadBasketPageAction = ReturnType<typeof reloadBasketPage>;
export type BroadcastBasketAction = ReturnType<typeof broadcastBasket>;
//Ticket 171387: Cambridge Isotope 3.9. End customer
export type EndCustomerAddressSelectedAction = ReturnType<typeof endCustomerAddressSelected>;
export type EndCustomerSelectAddressAction = ReturnType<typeof endCustomerSelectAddress>;
export type SendEndCustomerFormAction = ReturnType<typeof sendEndCustomerForm>;
export type EndCustomerAddressSubmittedAction = ReturnType<typeof endCustomerAddressSubmitted>;
export type ChangeBasketTypeAction = ReturnType<typeof changeBasketType>;/*171082 - 3.5.Two shopping carts (orders and quotes)*/
export type ChangeBasketTypeRedirectToCheckOutAction = ReturnType<typeof changeBasketTypeRedirectToCheckOut>;/*171082 - 3.5.Two shopping carts (orders and quotes)*/
export type BasketAction =
    | AddProductsAction
    | BasketUpdatedAction
    | BasketReceivedAction
    | BasketSummaryReceivedAction
    | BasketArrivedAction
    | ReceiveAgreementLinesAction
    | BasketUpdateAction
    | RequestBasketPageAction
    | ReloadBasketPageAction
    //Ticket 171387: Cambridge Isotope 3.9. End customer
    | EndCustomerAddressSelectedAction
    | SendEndCustomerFormAction
    | EndCustomerAddressSubmittedAction
    | EndCustomerSelectAddressAction
    | ChangeBasketTypeAction
    | ChangeBasketTypeRedirectToCheckOutAction
    /*171082 - 3.5.Two shopping carts(orders and quotes)*/
    | BasketHoverAction
    | ReturnType<
        | typeof requestBasketSummary
        | typeof clearBasket
        | typeof clearQuoteBasket
        | typeof requestAgreementLines
    >;
